<!--
 * @Author: clc frank.clc@qq.com
 * @Date: 2023-06-17 00:23:34
 * @LastEditors: clc frank.clc@qq.com
 * @LastEditTime: 2023-12-21 00:08:20
 * @FilePath: \bebe-likepepe\src\layout\classic\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <Header />
    <app-main />
    <Floor />
  </div>
</template>

<script>
import { AppMain, Header, Floor } from "./index";
export default {
  name: 'WebObjIndex1',
  components: { Header, AppMain, Floor },
  data () {
    return {

    };
  },

  mounted () {

  },

  methods: {

  },
};
</script>

<style lang="scss" scoped></style>