<template>
  <div class="content">
    尾部移动
  </div>
</template>

<script>
export default {
  name: "WebObjFloor",

  data() {
    return {
        
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  background-color: #e9ebee;
  .safety-zone{
    width: 100%;
    max-width: 992px;
    overflow: hidden;
    margin: 0 auto;
    padding: 60px 20px;
    box-sizing: border-box;
  }
}
.left-logo{
  width: 300px;
  display: flex;
  align-items: center;
  .logo-img{
    width: 50px;
    img{
      width: 100%;
    }
  }
  .logo-name{
    padding-left: 20px;
    font-size: 18px;
    letter-spacing:5px;
    font-weight: 600;
    
  }
}
.link-list{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;
    .tittle{
        font-size: 18px;
        color: #F6871E;
        margin-top: 40px;
    }
    .link-cont{
        padding: 20px 0;
        .link-name{
            font-size:16px ;
            line-height: 20px;
            padding: 5px 0;
            color: #475263;
        }
    }
    
}
.website-info{
    font-size:12px ;
    line-height: 20px;
    padding: 5px 0;
    color: #475263;
}
</style>