<template>
  <div class="content">
    <div class="header-content">
      <div class="left-cont">
        <div class="left-logo">
          <img src="@/assets/logo-cyberbear.png" alt="">
        </div>
        <div class="name-font">CyberBear</div>
      </div>
      <div class="right-cont">
        <div class="nav-item" @click="clickHandler('home')">home</div>
        <!-- <div class="nav-item" @click="clickHandler('about')">about</div> -->
        <div class="nav-item" @click="clickAbout()">docs</div>
        <div class="nav-item" @click="clickHandler('ecosystem')">CyberbearNFT</div>
        <div class="nav-item" @click="clickHandler('dobbyprereveal')">BtcDobby</div>
        <div class="nav-item" @click="clickHandler('tokenomics')"><span style='font-size: 15px;
    font-weight: bold;font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", 微软雅黑, Arial, sans-serif;'>$</span>ipip
        </div>
        <div class="nav-item" @click="clickHandler('roadmap')">roadmap</div>
        <el-button size="large" round dark :bg="true">
          <div class="btnitem" @click="onMintnow">
            Mint now
          </div>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch, onMounted } from 'vue'
import gsap from 'gsap'
import { useStore } from 'vuex'
onMounted(() => {
  gsap.to(".right-cont", 1, {
    y: function (index, target) {
      return -100
    }
  })
  gsap.to(".left-cont", 1, {
    y: function (index, target) {
      return 100
    }
  })

});

const $store = useStore();
const clickHandler = (val) => {
  $store.commit('setKnowNav', val)
}
const onMintnow = (val) => {
  window.open('https://opensea.io/collection/cyberbearnft/overview',"_blank")
}
const clickAbout = () => {
  window.open('https://docs.cyberbear.vip', '_blank')
  // location.href = 'https://docs.cyberbear.vip'
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  min-width: 1440px;
  position: relative;
  background-color: #80abff;
  top: 0;
  height: 105px;
  display: flex;
  align-items: center;
  z-index: 49;
  box-shadow: 0 5px 5px -4px rgba(0, 0, 0, .5)
}

.name-font {
  font-family: Bauhaus;
  color: #fff;
  font-size: 30px;
  padding-left: 15px;
}

.btnitem {
  font-family: Bauhaus;
  color: #fff;
  font-size: 8px;
}


.header-content {
  display: flex;
  align-items: center;
  max-width: 1240px;
  min-width: 1200px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  justify-content: space-between;

}

.left-cont {
  display: flex;
  align-items: center;
  position: relative;
  top: -100px;
}

.right-cont {
  display: flex;
  font-family: Bauhaus;
  color: #fff;
  font-size: 10px;
  align-items: center;
  position: relative;
  top: 100px;
}

.nav-item {
  padding: 15px;
  cursor: pointer;
}

.left-logo {
  width: 90px;
  display: flex;
  align-items: center;

  img {
    width: 100%;
  }

  .logo-name {
    padding-left: 20px;
    font-size: 22px;
    letter-spacing: 5px;
    font-weight: 600;

  }
}

.left-logo:hover {
  cursor: pointer;
}

:deep .el-button {
  --el-button-font-weight: var(--el-font-weight-primary);
  --el-button-border-color: #ffffff;
  --el-button-bg-color: transparent;
  --el-button-text-color: var(--el-text-color-regular);
  --el-button-disabled-text-color: var(--el-disabled-text-color);
  --el-button-disabled-bg-color: var(--el-fill-color-blank);
  --el-button-disabled-border-color: var(--el-border-color-light);
  --el-button-divide-border-color: rgba(255, 255, 255, 0.5);
  --el-button-hover-text-color: #ffffff;
  --el-button-hover-bg-color: rgba(255, 255, 255, 0.3);
  --el-button-hover-border-color: var(--el-color-primary-light-7);
  --el-button-active-text-color: var(--el-button-hover-text-color);
  --el-button-active-border-color: var(--el-color-primary);
  --el-button-active-bg-color: var(--el-button-hover-bg-color);
  --el-button-outline-color: var(--el-color-primary-light-5);
  --el-button-hover-link-text-color: var(--el-color-info);
  --el-button-active-color: var(--el-text-color-primary);
}
</style>