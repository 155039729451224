// 基础路由
const baseRouter = [{
      path: '/home',
      component: () => import('@/views/home/index.vue'),
      name: 'home',
      meta: {
        title: 'home',
        icon: '',
        affix: true,
        zhTitle: '首页'
      }
    }
]
export default baseRouter
