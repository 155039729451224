<!--
 * @Author: clc frank.clc@qq.com
 * @Date: 2023-06-17 00:23:34
 * @LastEditors: clc frank.clc@qq.com
 * @LastEditTime: 2023-12-20 22:34:52
 * @FilePath: \bebe-likepepe\src\layout\mobile\Header.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="content">
    头部移动

  </div>
</template>

<script>
export default {
  name: "WebObjHeader",

  data () {
    return {

    };
  },

  mounted () { },

  methods: {


  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  background-color: #fff;

  top: 0;
  z-index: 999999;

}

.header-content {
  align-items: center;
  max-width: 991px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
}

.el-menu {
  border-right: none;
  border-right-color: transparent;
}

.left-logo {
  width: 219px;
  display: flex;
  align-items: center;
  padding: 0 5px;

  .logo-img {
    width: 60px;

    img {
      width: 100%;
    }
  }

  .logo-name {
    padding-left: 20px;
    font-size: 22px;
    letter-spacing: 5px;
    font-weight: 600;

  }
}

.menu-nav {
  width: 100%;
  max-width: 991px;
  display: flex;
  position: relative;

}

.icon-menu {
  width: 40px;
  height: 40px;
  padding: 5px;

  img {
    width: 100%;
  }
}

.title-show {
  height: 0;
}</style>
