<!--
 * @Author: clc frank.clc@qq.com
 * @Date: 2023-06-17 00:23:34
 * @LastEditors: clc frank.clc@qq.com
 * @LastEditTime: 2023-12-20 23:58:58
 * @FilePath: \bebe-likepepe\src\layout\components\AppMain.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <section class="app-main">
    <router-view v-slot="{ Component }">
      <transition name="fade-transform" mode="out-in">
        <keep-alive :include="[]">
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {

  }
}
</script>

<style lang="scss" scoped></style>

