/*
 * @Author: clc frank.clc@qq.com
 * @Date: 2023-06-17 00:23:34
 * @LastEditors: clc frank.clc@qq.com
 * @LastEditTime: 2023-12-20 22:26:17
 * @FilePath: \bebe-likepepe\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue'

import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'

import App from './App.vue'
import router from './router'
import store from './store'
import '../src/assets/css/font.css'

const app = createApp(App).use(store).use(router)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.mount('#app')
app.use(ElementPlus)
