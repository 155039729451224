<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style >
*{
  border: 0;
  margin: 0;
}
</style>