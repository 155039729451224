import { createStore } from 'vuex'

export default createStore({
  state: {
    knowNav:''
  },
  getters: {
  },
  mutations: {
    setKnowNav(state,value){
      state.knowNav = value
    }
  },
  actions: {
  },
  modules: {
  }
})
