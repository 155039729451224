<!--
 * @Author: clc frank.clc@qq.com
 * @Date: 2023-06-18 01:37:39
 * @LastEditors: clc frank.clc@qq.com
 * @LastEditTime: 2023-12-20 14:24:23
 * @FilePath: \bebe-likepepe\src\layout\classic\Floor.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="content">
    <div class="safety-zone">
      © 2023 by X token Labs. All rights reserved!
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  min-width: 1440px;
  position: relative;
  background-color: #80abff;
  height: 60px;
  display: flex;
  align-items: center;
  z-index: 49;
  box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.4);

  .safety-zone {
    width: 100%;
    // min-width: 1440px;
    overflow: hidden;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
}
</style>