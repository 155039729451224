/*
 * @Author: clc frank.clc@qq.com
 * @Date: 2023-06-18 14:17:53
 * @LastEditors: clc frank.clc@qq.com
 * @LastEditTime: 2023-12-20 23:50:16
 * @FilePath: \bebe-likepepe\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/layout'
import baseRouters from '@/router/modules/base'
const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [...baseRouters],
  },
]
// 解决：vue切换页面/路由跳转后，页面不会置顶
const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition && to.meta.keepAlive) {
    return savedPosition
  }
  // return { left: 0, top: 0 };
  return { x: 0, y: 0 }
}

const router = createRouter({
  mode: 'hash',
  history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
})

// const router = createRouter({
//   history: createWebHashHistory(),
//   routes
// })

export default router
